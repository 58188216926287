import storage from '@utils/storage';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function InitCoachSession() {
  const { refreshToken } = useParams<{ refreshToken: string }>();

  useEffect(() => {
    storage.setRefreshToken(refreshToken);
    window.location.href = '/';
  }, [refreshToken]);

  return null;
}
