import { CommonState } from './types';
import { RootState } from '@state/types';

/**
 * returns true when some of actions are loading
 */
export const createLoadingSelector = (actions: string[]) => (state: CommonState): boolean => {
  return !actions.every(action => state.loaders.hasOwnProperty(action)) || actions.some(action => state.loaders[action]);
};

/**
 * returns the first error messages for actions
 * We assume when any request fails on a page that requires multiple API calls, we shows the first error
 */
export const createErrorMessageSelector = (actions: string[]) => (state: CommonState): string =>
  actions.map(action => state.errors[action]).filter(action => action)[0] || '';

export const getConfirmationDialogState = (state: CommonState) => state.confirmationDialog;

export const isConfirmationOpen = (state: RootState) => state.common.confirmationDialog.opened;

export const getAppLoaded = (state: RootState) => ({ appLoaded: state.common.appLoaded });

export const getSubscriptionExpired = (state: RootState) => ({ subscriptionExpired: state.common.subscriptionExpired });

export const getVerificationRequired = (state: RootState) => ({ verificationRequired: state.common.verificationRequired });

export const getIsCoachSession = (state: RootState) => ({ isCoachSession: state.common.isCoachSession });

export const getActionLoaders = (state: RootState) => state.common.loaders;

export const getActionErrors = (state: RootState) => state.common.errors;

export const getLinkToGoogleCalDialogParams = (state: RootState) => state.common.linkGoogleCalendarDialogParams;

export const getParentLink = (state: RootState) => state.common.isParentLink;

export const isAccessibilityOn = (state: RootState) => state.common.accessibilityOn;

export const getServiceWorkerData = (state: RootState) => state.common.serviceWorkerData;
