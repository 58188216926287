import { getType } from 'typesafe-actions';

import { CommonState, ConfirmationDialogInfo, ServiceWorkerData } from './types';
import {
  appLoaded,
  closeConfirmationDialog,
  goToSuccessPaymentPage,
  onAppStart,
  openConfirmationDialog,
  setAccessibility,
  setCoachSessionFlag,
  setParentLink,
  subscriptionExpired,
  subscriptionRenewed,
  toggleLinkToGoogleCalendarDialog,
  verificationRequired
} from './actions';
import * as actions from '../task/actions';
import { getManageTask, saveManageTask } from '../task/actions';
import storage from '../../utils/storage';

const initialState: CommonState = {
  loaders: {},
  errors: {},
  linkGoogleCalendarDialogParams: {
    opened: false,
    multiselect: false
  },
  confirmationDialog: {
    opened: false,
    understandCheck: false,
    title: '',
    message: ''
  },
  appLoaded: false,
  subscriptionExpired: false,
  verificationRequired: false,
  isParentLink: false,
  accessibilityOn: storage.isAccessibilityOn(),
  serviceWorkerData: {
    firstTime: false,
    newVersionAvailable: false,
    updateDone: false
  },
  isCoachSession: false
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  if (type === getType(appLoaded)) {
    return handleAppLoaded(state);
  }

  if (type === getType(subscriptionExpired)) {
    return handleSubscriptionExpired(state);
  }

  if (type === getType(subscriptionRenewed)) {
    return handleSubscriptionRenewed(state);
  }

  if (type === getType(verificationRequired)) {
    return handleVerificationRequired(state);
  }

  if (type === getType(setCoachSessionFlag)) {
    return handleSetCoachSessionFlag(state);
  }

  if (type === getType(onAppStart)) {
    return handleOnAppStart(state);
  }

  if (type === getType(openConfirmationDialog)) {
    return handleOpenConfirmationDialog(state, payload);
  }

  if (type === getType(closeConfirmationDialog)) {
    return handleCloseConfirmationDialog(state);
  }

  if (type === getType(actions.closeManageTaskDialog)) {
    return handleCloseManageTaskDialog(state);
  }

  if (type === getType(toggleLinkToGoogleCalendarDialog)) {
    return handleToggleLinkToGoogleCalendarDialog(state, payload);
  }

  if (type === getType(setParentLink)) {
    return handleSetParentLink(state);
  }

  if (type === getType(goToSuccessPaymentPage)) {
    return handleGoToSuccessPaymentPage(state);
  }

  if (type === getType(setAccessibility)) {
    return handleSetAccessibility(state, action.payload);
  }

  if (type === 'SW') {
    return handleServiceWorker(state, action.payload);
  }

  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    loaders: {
      ...state.loaders,
      // Store whether a request is happening at the moment or not
      [requestName]: requestState === 'REQUEST'
    },
    errors: {
      ...state.errors,
      // Store errorMessage
      [requestName]: requestState === 'FAILURE' ? payload : ''
    }
  };
};

const handleOpenConfirmationDialog = (state: CommonState, payload: ConfirmationDialogInfo) => {
  return {
    ...state,
    confirmationDialog: {
      opened: true,
      ...payload
    }
  };
};

const handleCloseConfirmationDialog = (state: CommonState) => {
  return {
    ...state,
    confirmationDialog: {
      opened: false,
      understandCheck: false,
      title: '',
      message: ''
    }
  };
};

const handleCloseManageTaskDialog = (state: CommonState) => {
  const types = [getManageTask, saveManageTask];
  const newState = types
    .map(type => ({ [getType(type.failure).split('_FAILURE')[0]]: '' }))
    .reduce((obj, error) => ({ ...obj, ...error }), {});

  return {
    ...state,
    errors: {
      ...state.errors,
      ...newState
    }
  };
};

const handleToggleLinkToGoogleCalendarDialog = (state: CommonState, payload: { id: number; multiselect: boolean }) => {
  const shouldOpen = !state.linkGoogleCalendarDialogParams.opened;
  const { id, multiselect = false } = payload;
  return {
    ...state,
    linkGoogleCalendarDialogParams: {
      opened: shouldOpen,
      courseId: id,
      multiselect
    }
  };
};

const handleAppLoaded = (state: CommonState) => {
  return {
    ...state,
    appLoaded: true
  };
};

const handleSubscriptionExpired = (state: CommonState) => {
  return {
    ...state,
    subscriptionExpired: true
  };
};

const handleSubscriptionRenewed = (state: CommonState) => {
  return {
    ...state,
    subscriptionExpired: false
  };
};

const handleStartNewSubscription = (state: CommonState) => {
  return {
    ...state,
    subscriptionExpired: false
  };
};

const handleVerificationRequired = (state: CommonState) => {
  return {
    ...state,
    verificationRequired: true
  };
};

const handleSetCoachSessionFlag = (state: CommonState) => {
  return {
    ...state,
    isCoachSession: true
  };
};

const handleOnAppStart = (state: CommonState) => {
  return {
    ...state,
    subscriptionExpired: false
  };
};

const handleSetParentLink = (state: CommonState) => {
  return {
    ...state,
    isParentLink: true
  };
};

const handleGoToSuccessPaymentPage = (state: CommonState) => {
  return {
    ...state,
    isParentLink: false
  };
};

const handleSetAccessibility = (state: CommonState, payload: boolean) => ({
  ...state,
  accessibilityOn: payload
});

const handleServiceWorker = (state: CommonState, payload: ServiceWorkerData) => ({
  ...state,
  serviceWorkerData: payload
});

export default reducer;
