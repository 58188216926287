import { actionCreator } from './utils';
import { ConfirmationDialogInfo, AppStartRequest } from './types';
import { createStandardAction } from 'typesafe-actions';

const createAction = actionCreator('@common');

export const openConfirmationDialog = createAction<ConfirmationDialogInfo>('OPEN_CONFIRMATION_DIALOG');

export const closeConfirmationDialog = createAction<undefined>('CLOSE_CONFIRMATION_DIALOG');

export const clearState = createAction<undefined>('CLEAR_STATE');

export const onAppStart = createAction<AppStartRequest>('ON_APP_START');

export const appLoaded = createAction<undefined>('APP_LOADED');

export const subscriptionExpired = createAction<undefined>('SUBSCRIPTION_EXPIRED');

export const subscriptionRenewed = createAction<undefined>('SUBSCRIPTION_RENEWED');

export const verificationRequired = createAction<undefined>('VERIFICATION_REQUIRED');

export const toggleLinkToGoogleCalendarDialog = createAction<{
  id?: number;
  googleCalendarId?: string;
  multiselect?: boolean;
}>('TOGGLE_LINK_TO_GOOGLE_CALENDAR');

export const linkOrUnlinkGoogleCalendar = createAction<{ id: number; googleCalendarId?: string }>(
  'LINK_OR_UNLINK_GOOGLE_CALENDAR'
);

export const setParentLink = createAction<undefined>('SET_PARENT_LINK');

export const goToSuccessPaymentPage = createAction<string>('GO_TO_SUCCESS_PAYMENT');

export const toggleAccessibility = createAction<undefined>('TOGGLE_ACCESSIBILITY');

export const setAccessibility = createAction<boolean>('SET_ACCESSIBILITY');

export const setCoachSessionFlag = createAction<undefined>('SET_COACH_SESSION_FLAG');
