import { ReactElement } from 'react';

export type CommonState = {
  loaders: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: string;
  };
  linkGoogleCalendarDialogParams: {
    // todo renaame
    opened: boolean;
    multiselect: boolean;
    courseId?: number;
  };
  confirmationDialog: ConfirmationDialogState;
  appLoaded: boolean;
  subscriptionExpired: boolean;
  verificationRequired: boolean;
  isParentLink: boolean;
  accessibilityOn: boolean;
  serviceWorkerData: ServiceWorkerData;
  isCoachSession: boolean;
};

export type ServiceWorkerData = {
  firstTime: boolean;
  newVersionAvailable: boolean;
  updateDone: boolean;
  registration?: ServiceWorkerRegistration | undefined;
};

export type ConfirmationDialogState = {
  opened: boolean;
} & ConfirmationDialogInfo;

export type ConfirmationDialogInfo = {
  action?: () => any;
  loadingAction?: any;
  title: string;
  message: string | ReactElement;
  understandCheck?: boolean;
  callback?: () => any;
  cancelLabel?: string;
  okLabel?: string;
  onCancel?: () => void;
  warning?: boolean;
  hideCancel?: boolean;
  additionalAction?: () => void;
  additionalActionLabel?: string;
  className?: string;
  overlayStyles?: any;
  renderCustomOkButton?: any;
};

export enum CalendarMode {
  TASKS = 'TASKS',
  AWAKE = 'AWAKE',
  COMMITMENTS = 'COMMITMENTS',
  STUDY_TIME = 'STUDY_TIME'
}

export type AppStartRequest = {
  location: any;
};
