import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { Route, Switch, useHistory } from 'react-router-dom';

import './polyfills';
import './config/reactotronConfig';

import CalendarLoader from './components/calendar/CalendarLoader.loader';
import ConfirmationDialog from './components/common/dialog/ConfirmationDialog';
import MainNavigation from './components/navigation/MainNavigation';
import { FlexColumn, Row } from './components/common/layoutUtils';
import Login from './components/auth/Login.loader';
import ForgotPassword from './components/auth/ForgotPassword.loader';
import * as routes from './utils/constants/routes';
import ResetPassword from './components/auth/ResetPassword.loader';
import LinkExpired from './components/auth/LinkExpired.loader';
import { bindActionCreators, Dispatch } from 'redux';
import { commonActions } from './state/common';
import Settings from './components/settings/Settings.loader';
import { RootState } from '@state/types';
import {
  getAppLoaded,
  getIsCoachSession,
  getParentLink,
  getSubscriptionExpired,
  getVerificationRequired
} from './state/common/selectors';
import ConditionalRoutes from './ConditionalRoutes';
import Charts from './components/charts/Charts.loader';
import ManageTaskDialog from './components/task/manage/ManageTaskDialog';
import CongratsMobile from './components/auth/CongratsMobile.loader';
import { isMobile, isMobileApp, isTouchScreen } from './utils/screenUtils';
import GpaCalculator from './components/gpaCalculator/GpaCalculator.loader';
import NotFound from '@components/common/pages/NotFound.loader';
import BrowserNotSupported from '@components/common/pages/BrowserNotSupported.loader';
import MaintenancePage from '@components/common/pages/MaintenancePage.loader';
import ServerErrorPage from '@components/common/pages/ServerErrorPage.loader';
import { darkTheme, lightTheme } from './assets/themes';
import { DatePickerStyles } from './components/common/inputs/Input.styles';
import storage from './utils/storage';
import Notifications from './components/notifications/Notifications.loader';
import TasksDialog from './components/task/TasksDialog';
import StudyTime from './components/calendar/StudyTime.loader';
import AwakeTime from './components/calendar/AwakeTime.loader';
import SetupOnboarding from './components/onboardingSetup/quickSetup/SetupOnboarding.loader';
import ExpiredMembershipPage from './components/settings/membership/ExpiredMembershipPage.loader';
import Badges from './components/settings/Badges.loader';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { googleRecaptchaKey, urls } from './config/configConstants';
import colors from './utils/colors';
import { RegularText } from './utils/typography';
import t from './i18n/t';
import { SemesterUser } from 'shovel-lib/types';
import { getCurrentUser, getId } from './state/semester/selectors';
import { authenticationApi, timeUtils } from 'shovel-lib';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import { getErrorMessage } from './api/network';
import SignUpOrganization from './components/auth/SignUpOrganization.loader';
import VerifyEmail from './components/auth/VerifyEmail.loader';
import CalendarPage from './components/mobile/CalendarPage.loader';
import LinkGoogleCalToCourseDialog from './components/common/googleCalendar/LinkGoogleToCourseDialog';
import OnboardingDemo from './components/onboardingSetup/demo/OnboardingDemo.loader';
import EditorPage from './components/mobile/EditorPage.loader';
import { CalendarMode } from './state/common/types';
import Commitments from './components/calendar/Commitments.loader';
import PersonalizationDialog from '@components/settings/personalization/PersonalizationDialog';
import ParentLinkPage from './components/settings/membership/ParentLinkPage.loader';
import SuccessPaymentPage from './components/settings/membership/SuccessPaymentPage.loader';
import { rawColors } from './utils/theme/rawColors';
import TaskListPage from './components/calendar/TaskListPage.loader';
import ImportCourseConfirmationDialog from './components/course/ImportCourseConfirmationDialog';
import CreateTaskOverlay from './components/task/CreateTaskOverlay';
import RepeatPatternDialog from './components/task/createTask/RepeatPatternDialog';
import NewEstimateDialog from './components/task/manage/partials/NewEstimateDialog';
import { timerActions } from './state/timer/index';
import MobileSignUpInstructions from './components/auth/MobileSignUpInstructions';
import ResendActivationEmail from './components/auth/ResendActivationEmail';
import IcsUpdatesDialog from './components/calendar/dialogs/IcsUpdatesDialog';
import { getIcsUpdatesTimestamp } from './state/ics/selectors';
import { icsActions } from './state/ics';
import './assets/css/styles.css';
import './assets/css/datepicker.css';
import 'rc-slider/assets/index.css';
import SignUpMobileInfo from '@components/auth/SignUpMobileInfo';
import { ApiErrorResponse, ApiResponse } from 'apisauce';
import InitCoachSession from '@components/coach/InitCoachSession';

type PropsFromState = {
  appLoaded: boolean;
  subscriptionExpired: boolean;
  verificationRequired: boolean;
  user?: SemesterUser;
  semesterId?: number;
  isParentLink: boolean;
  icsUpdateTimestamp?: string;
  isCoachSession: boolean;
};

type PropsFromDispatch = {
  onAppStart: typeof commonActions.onAppStart;
  getCurrentTimer: typeof timerActions.getCurrentTimer.request;
  checkIcsUpdates: typeof icsActions.checkForIcsUpdates;
};

type Props = PropsFromState & PropsFromDispatch;

const checkTimer = (getCurrentTimer: any) => {
  getCurrentTimer();
};

const App: FC<Props> = ({
  onAppStart,
  appLoaded,
  subscriptionExpired,
  verificationRequired,
  user,
  icsUpdateTimestamp,
  isParentLink,
  isCoachSession,
  getCurrentTimer,
  checkIcsUpdates
}) => {
  const history = useHistory();

  useEffect(() => {
    onAppStart({ location: history.location });
  }, []);

  useEffect(() => {
    checkTimer(getCurrentTimer);
    // const interval = setInterval(() => checkTimer(getCurrentTimer), 10000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    if (!icsUpdateTimestamp) return;

    const now = timeUtils.now();
    const nextUpdate = timeUtils.toMomentDate(icsUpdateTimestamp).add(1, 'day');
    const timeout = setTimeout(() => checkIcsUpdates(), Math.max(nextUpdate.diff(now, 'milliseconds'), 0));
    return () => {
      clearTimeout(timeout);
    };
  }, [icsUpdateTimestamp]);

  const [theme, setTheme] = useState(storage.getTheme());

  const switchTheme = () => {
    const newTheme = theme !== 'light' ? 'light' : 'dark';
    storage.setTheme(newTheme);
    setTheme(newTheme);
  };

  if (!appLoaded) {
    return null;
  }

  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  if (subscriptionExpired) {
    return (
      <ThemeProvider theme={currentTheme}>
        <Container>
          <Route
            path={routes.EXPIRED_SUBSCRIPTION}
            render={props => {
              return <ExpiredMembershipPage {...props} toggleMode={switchTheme} darkMode={theme === 'dark'} />;
            }}
          />
        </Container>
      </ThemeProvider>
    );
  }

  if (verificationRequired) {
    return (
      <ThemeProvider theme={currentTheme}>
        <Container>
          <Route path={routes.VERIFY_EMAIL} component={VerifyEmail} />
        </Container>
      </ThemeProvider>
    );
  }

  if (isParentLink) {
    return (
      <ThemeProvider theme={currentTheme}>
        <Container>
          <Route path={routes.PARENT_LINK} component={ParentLinkPage} />
        </Container>
      </ThemeProvider>
    );
  }

  if (isMobileApp || isMobile) {
    return (
      <ThemeProvider theme={currentTheme}>
        <Container>
          <Switch>
            <Route path={routes.PAYMENT_SUCCESS} component={SuccessPaymentPage} />
            <Route path={routes.SIDEBAR} component={Commitments} />
            <Route path={routes.EDITOR} component={EditorPage} />
            {/*<ConditionalRoutes path={routes.MOBILE_ONLY_PRIVATE_ROUTE_PREFIX} isPrivate={true}>*/}
            <Route path={routes.MOBILE_CALENDAR} component={CalendarPage} />
            {isMobileApp && <Route path={routes.TERM} component={SetupOnboarding} />}
            {/*</ConditionalRoutes>*/}
            <Route path={routes.CONGRATS_MOBILE} component={CongratsMobile} />
            <Route path={routes.MOBILE_FORGOT_PASSWORD} component={ForgotPassword} />
            <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
            <Route path={routes.LINK_EXPIRED} component={LinkExpired} />
            <Route path={routes.TERM} component={SetupOnboarding} />
            <Route path={routes.MOBILE_SIGNUP_INSTRUCTIONS} component={MobileSignUpInstructions} />
            <Route path={routes.RESEND_ACTIVATION_EMAIL} component={ResendActivationEmail} />
            <Route
              path={routes.SIGN_UP_ORGANIZATION}
              render={props => (
                <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaKey}>
                  <SignUpOrganization {...props} />
                </GoogleReCaptchaProvider>
              )}
            />
            <Route exact path={routes.SIGN_UP} component={SignUpMobileInfo} />
            {/*<Route path={'/'} component={AccessScreenMobile} />*/}
          </Switch>
        </Container>
        <ConfirmationDialog />
        <LinkGoogleCalToCourseDialog />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <Container>
        <Switch>
          <Route path={routes.BROWSER_NOT_SUPPORTED} component={BrowserNotSupported} />
          <Route path={routes.MAINTENANCE} component={MaintenancePage} />
          <Route path={routes.SERVER_ERROR} component={ServerErrorPage} />
          <Route path={routes.PAYMENT_SUCCESS} component={SuccessPaymentPage} />
          <Route path={routes.INIT_COACH_SESSION} component={InitCoachSession} />
          <ConditionalRoutes path={routes.AUTH_ROUTE_PREFIX} isPrivate={false}>
            <Switch>
              <Route path={routes.LOGIN} component={Login} />
              <Route path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
              <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
              <Route path={routes.RESEND_ACTIVATION_EMAIL} component={ResendActivationEmail} />
              <Route
                path={routes.SET_PASSWORD}
                render={props => {
                  return <ResetPassword {...props} isInvitation />;
                }}
              />
              <Route path={routes.LINK_EXPIRED} component={LinkExpired} />
              <Route
                path={routes.SIGN_UP_ORGANIZATION}
                render={props => (
                  <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaKey}>
                    <SignUpOrganization {...props} />
                  </GoogleReCaptchaProvider>
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </ConditionalRoutes>
          <ConditionalRoutes path={'/'} isPrivate={true}>
            <ContentWrapper>
              <MainNavigation history={history} toggleMode={switchTheme} darkMode={theme === 'dark'} />
              <Content>
                {user?.emailVerified === false && (
                  <VerifyEmailBar>
                    <RegularText color={colors.white}>
                      {t.VERIFY_EMAIL_MESSAGE} {user?.email}
                    </RegularText>
                    <ResendLink
                      onClick={async () => {
                        const response = await authenticationApi.resendEmailVerificationLink();
                        if (response.ok) {
                          alert(t.EMAIL_VERIFICATION_LINK_RESENT, NotificationMode.SUCCESS);
                        } else {
                          alert(t[getErrorMessage(response as ApiErrorResponse<any>)]);
                        }
                      }}
                    >
                      {t.RESEND}
                    </ResendLink>
                  </VerifyEmailBar>
                )}
                {isCoachSession && (
                  <CoachSessionBar>
                    <RegularText color={colors.white}>You are logged in as {user.email}</RegularText>
                    <CoachSessionBackLink
                      onClick={() => {
                        window.location.href = urls.coachPlatformUrl;
                      }}
                    >
                      Back to Shovel Coach
                    </CoachSessionBackLink>
                  </CoachSessionBar>
                )}
                <Switch>
                  <Route path={routes.CALENDAR} component={CalendarLoader} />
                  <Route path={routes.TASKS} component={TaskListPage} />
                  <Route path={routes.TERM} component={SetupOnboarding} />
                  <Route path={routes.SETTINGS} component={Settings} />
                  <Route path={routes.CHARTS} component={Charts} />
                  <Route path={routes.GPA_CALCULATOR} component={GpaCalculator} />
                  <Route path={routes.NOTIFICATIONS} component={Notifications} />
                  <Route path={routes.AWAKE_TIME} component={props => <AwakeTime {...props} mode={CalendarMode.AWAKE} />} />
                  <Route path={routes.STUDY_TIME} component={StudyTime} />
                  <Route path={routes.BADGES} component={Badges} />
                  <Route path={routes.DEMO} component={OnboardingDemo} />
                  <Route component={NotFound} />
                </Switch>
              </Content>
            </ContentWrapper>
          </ConditionalRoutes>
        </Switch>
        <ManageTaskDialog />
        <TasksDialog />
        <ConfirmationDialog />
        <LinkGoogleCalToCourseDialog />
        <PersonalizationDialog />
        <ImportCourseConfirmationDialog />
        <CreateTaskOverlay />
        <RepeatPatternDialog />
        <NewEstimateDialog />
        <IcsUpdatesDialog />
      </Container>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...getAppLoaded(state),
  ...getSubscriptionExpired(state),
  ...getVerificationRequired(state),
  ...getIsCoachSession(state),
  user: getCurrentUser(state),
  semesterId: getId(state),
  isParentLink: getParentLink(state),
  icsUpdateTimestamp: getIcsUpdatesTimestamp(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onAppStart: commonActions.onAppStart,
      getCurrentTimer: timerActions.getCurrentTimer.request,
      checkIcsUpdates: icsActions.checkForIcsUpdates
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundDark};
  transition: background-color 0.2s ease;
  ${isTouchScreen && '.delete-button { visibility: visible !important; }'};

  ${DatePickerStyles}
  ~ * {
    ${DatePickerStyles}
  }

  svg:not(.theme-icon) {
    > *[fill='${rawColors.purple}'] {
      fill: ${({ theme }) => theme.primary};
    }
    > *[fill='${rawColors.darkPurple}'] {
      fill: ${({ theme }) => theme.primaryDark};
    }
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const Content = styled(FlexColumn)`
  box-sizing: border-box;
  padding-left: 2px;
`;

const VerifyEmailBar = styled(Row)`
  height: 30px;
  background-color: ${colors.primaryPurple};
  justify-content: center;
`;

const CoachSessionBar = styled(Row)`
  height: 30px;
  background-color: ${colors.primaryPurple};
  justify-content: center;
`;

const ResendLink = styled(RegularText)`
  display: block;
  margin-left: 15px;
  color: ${colors.white};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;

const CoachSessionBackLink = styled(RegularText)`
  display: block;
  margin-left: 15px;
  color: ${colors.white};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;
